<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="검진 결과 상세" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-3">
                <c-plant type="search" name="plantCd" v-model="data.plantCd" />
              </div>
              <div class="col-3">
                <c-dept type="search" label="LBLDEPT" name="deptCd" v-model="data.deptCd" />
              </div>
              <div class="col-3">
                <c-text
                  label="사용자"
                  name="userName"
                  v-model="data.userName">
                </c-text>
              </div>
              <div class="col-3">
                <c-datepicker
                  label="검진일"
                  name="col1"
                  v-model="data.col1"
                />
              </div>
              <div class="col-12">
                <c-card title="소견" class="cardClassDetailForm">
                  <template slot="card-detail">
                    <div class="col-3">
                      <c-select
                        :comboItems="Items1"
                        type="edit"
                        itemText="codeName"
                        itemValue="code"
                        name="col2"
                        label="업무수행적합"
                        v-model="data.col2"
                      ></c-select>
                    </div>
                    <div class="col-3">
                      <c-select
                        :comboItems="Items2"
                        type="edit"
                        itemText="codeName"
                        itemValue="code"
                        name="col3"
                        label="사후관리"
                        v-model="data.col3"
                      ></c-select>
                    </div>
                    <div class="col-6">
                      <c-text
                        label="종합소견"
                        name="col4"
                        v-model="data.col4">
                      </c-text>
                    </div>
                  </template>
                </c-card>
              </div>
              <div class="col-12">
                <c-table
                  ref="table1"
                  title="판정 결과"
                  tableId="table1"
                  :columns="grid1.columns"
                  :gridHeight="grid1.height"
                  :data="grid1.data"
                  :columnSetting="false"
                  :isFullScreen="false"
                  :usePaging="true"
                  :expandAll="true"
                >
                </c-table>
              </div>
              <div class="col-12">
                <c-table
                  ref="table2"
                  title="검진항목 결과"
                  tableId="table2"
                  :columns="grid2.columns"
                  :gridHeight="grid2.height"
                  :data="grid2.data"
                  :columnSetting="false"
                  :isFullScreen="false"
                  :usePaging="true"
                  :expandAll="true"
                >
                </c-table>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
export default {
  name: 'check-up-result',
  props: {
    param: {
      type: Object,
      default: () => ({
        checkUpId: '',
      }),
    },
  },
  data() {
    return {
      editable: false,
      Items1: [
        { code: '1', codeName: '현재조건 작업가능' },
        { code: '2', codeName: '일장조건 작업가능' },
      ],
      Items2: [
        { code: '1', codeName: '필요없음' },
        { code: '2', codeName: '건강상담' },
      ],
      col3Items: [
        { code: 'A', codeName: 'A' },
        { code: 'B', codeName: 'B' },
        { code: 'C', codeName: 'C' },
        { code: 'C1', codeName: 'C1' },
        { code: 'C2', codeName: 'C2' },
        { code: 'CN', codeName: 'CN' },
        { code: 'D1', codeName: 'D1' },
        { code: 'D2', codeName: 'D2' },
        { code: 'DN', codeName: 'DN' },
        { code: 'R', codeName: 'R' },
        { code: 'U', codeName: 'U' },
      ],
      bbItems: [
        { code: 'Y', codeName: '적용' },
        { code: 'N', codeName: '미적용' },
      ],
      grid1: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '판정',
            align: 'center',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '질환종류',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '질환',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col4',
            field: 'col4',
            label: 'LBLREMARK',
            align: 'left',
            sortable: false,
          },
        ],
        height: '238px',
        data: [],
      },
      grid2: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '검진항목 종류',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '검진항목',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '이전검진 결과',
            align: 'center',
            style: 'width: 150px',
            sortable: false,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '결과',
            align: 'left',
            style: 'width: 150px',
            sortable: false,
          },
        ],
        height: '238px',
        data: [],
      },
      data: {
        plantCd: null,
        deptCd: '',
        userName: '박부장',
        col1: '2022-01-13',
        col2: '2',
        col3: '1',
        col4: '종합소견',
        col5: '',
        col6: '',
        col7: '',
        col8: '',
        col9: '',
        col10: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.getUserList();
    },
    getUserList() {
      this.grid1.data = [
        {
          col1: 'D1', 
          col2: '소화기계의 질환', 
          col3: '식도역류증', 
          col4: '', 
        },
      ]
      this.grid2.data = [
        {
          col1: '간기능검사', 
          col2: '감마지티피', 
          col3: '23 I/UL', 
          col4: '30 I/UL', 
        },
      ]
    },
    reset() {
    },
  }
};
</script>